import { Link, Navigate, useNavigate } from 'react-router-dom';
import { ArrowLeftIcon, PlusCircleIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
interface BreadcrumbProps {
  pageName: string;
  addbtn?: boolean;
  addRouter?: string;
  buttons?: JSX.Element[];
}
const Breadcrumb = ({
  pageName,
  addRouter = '',
  addbtn = false,
  buttons,
}: BreadcrumbProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="mb-6 flex flex-col gap-3 sm:flex-row sm:items-center sm:justify-between">
      <div className="flex  items-center gap-2">
        <ArrowLeftIcon
          onClick={() => navigate(-1)}
          className="h-5 w-5 text-white "
        />
        <h2 className="text-title-md2 font-semibold text-black dark:text-white">
          {pageName}
        </h2>
        {addbtn && (
          <Link to={addRouter}>
            <PlusCircleIcon
              className="h-5 w-5 text-primary"
              aria-hidden="true"
            />
          </Link>
        )}
        {buttons &&
          buttons.map((button, index) => <div key={index}>{button}</div>)}
      </div>

      <nav>
        <ol className="flex items-center gap-2">
          <li>
            <Link to="/">{t('sidebar.dashbord')} /</Link>
          </li>
          <li className="text-primary">{pageName}</li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;

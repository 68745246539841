import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import DatePickerDefault from '@/components/DatePicker';

import {
  Equipment,
  getEquipment,
  saveEquipment,
} from '@/services/EquipmentService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import {
  EquipmentCategory,
  EquipmentCategoryOptions,
} from '@/enums/EquipmentCategory';
import Checkbox from '@/components/Checkbox';
import {
  EquipmentStatus,
  EquipmentStatusOptions,
} from '@/enums/EquipmentStatus';
import FormButtons from '@/components/shared/FormButtons';
import { Currency, currencyOptions } from '@/enums/Currency';

const validationSchema = Yup.object().shape({
  equipmentName: Yup.string().required('EquipmentForm.equipmentNameRequired'),
  type: Yup.string().required('EquipmentForm.typeRequired'),
  category: Yup.string().required('EquipmentForm.categoryRequired'),
  brand: Yup.string().required('EquipmentForm.brandRequired'),
  model: Yup.string().required('EquipmentForm.modelRequired'),
  weightInTons: Yup.number().required('EquipmentForm.weightInTonsRequired'),
  power: Yup.number().required('EquipmentForm.powerRequired'),
  serialNumber: Yup.string().required('EquipmentForm.serialNumberRequired'),
  location: Yup.string().required('EquipmentForm.locationRequired'),
  onSite: Yup.boolean().required('EquipmentForm.onSiteRequired'),
  availableDate: Yup.date().required('EquipmentForm.availableDateRequired'),
  purchaseDate: Yup.date().required('EquipmentForm.purchaseDateRequired'),
  cost: Yup.number().required('EquipmentForm.costRequired'),
  lastMaintenanceDate: Yup.date().required(
    'EquipmentForm.lastMaintenanceDateRequired'
  ),
  nextMaintenanceDate: Yup.date().required(
    'EquipmentForm.nextMaintenanceDateRequired'
  ),
  // status: Yup.string().required('EquipmentForm.statusRequired'),
  locationDetails: Yup.string().required(
    'EquipmentForm.locationDetailsRequired'
  ),
  responsiblePerson: Yup.string().required(
    'EquipmentForm.responsiblePersonRequired'
  ),
});

interface FormValues extends Equipment {}

function EquipmentForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    equipmentName: '',
    type: '',
    category: EquipmentCategory.Equipment,
    brand: '',
    model: '',
    weightInTons: 0,
    power: 0,
    serialNumber: '',
    location: '',
    onSite: false,
    availableDate: new Date(),
    purchaseDate: new Date(),
    cost: 0,
    lastMaintenanceDate: new Date(),
    nextMaintenanceDate: new Date(),
    status: EquipmentStatus.UnderTest,
    locationDetails: '',
    responsiblePerson: '',
    currency: Currency.EUR,
  });
  const [loading, setLoading] = useState(false);

  const {
    data: equipmentData,
    isLoading,
    refetch: refetchEquipment,
  } = useQuery<Equipment | undefined>(
    ['equipment', id],
    async () => await getEquipment(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
      onSuccess: (equipment) => {
        if (equipment) {
          setFormikValuesState((prev) => ({ ...prev, ...equipment }));
        }
      },
    }
  );

  useEffect(() => {
    if (editForm && equipmentData) {
      setFormikValuesState((prev) => ({ ...prev, ...equipmentData }));
    }
  }, [editForm, equipmentData]);

  const handleSaveEquipment = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const companyId = getCompanyId()! as string;
      const equipment: Equipment = {
        ...values,
        companyId: companyId!,
      };

      const data = await saveEquipment(equipment);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Equipment'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Equipment'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchEquipment();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm
            ? t('EquipmentForm.editEquipment')
            : t('EquipmentForm.addEquipment')
        }
      />
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveEquipment}
          enableReinitialize={editForm}
        >
          {({ values, errors, handleChange, handleSubmit, resetForm }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="equipmentName"
                    id="equipmentName"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.equipmentName}
                    label={t('EquipmentForm.equipmentNameLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="type"
                    id="type"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.type}
                    label={t('EquipmentForm.typeLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  {/* <DefaultInput
                  type="text"
                  name="category"
                  id="category"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.category}
                  label={t('EquipmentForm.categoryLabel')}
                /> */}

                  <Dropdown
                    name="category"
                    id="category"
                    label={t('EquipmentForm.categoryLabel')}
                    value={values.category}
                    translatedNameSpace="EquipmentCategory"
                    isTranslated={true}
                    optionTransalatedKey="name"
                    optionValue="id"
                    labelClassName="pl-0"
                    error={errors.category}
                    options={EquipmentCategoryOptions ?? []}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="brand"
                    id="brand"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.brand}
                    label={t('EquipmentForm.brandLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="model"
                    id="model"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.model}
                    label={t('EquipmentForm.modelLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="weightInTons"
                    id="weightInTons"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.weightInTons}
                    label={t('EquipmentForm.weightInTonsLabel')}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="power"
                    id="power"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.power}
                    label={t('EquipmentForm.powerLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="serialNumber"
                    id="serialNumber"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.serialNumber}
                    label={t('EquipmentForm.serialNumberLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="location"
                    id="location"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.location}
                    label={t('EquipmentForm.locationLabel')}
                  />
                </div>
                <div className="group relative z-0   flex w-full items-center ">
                  <Checkbox
                    name="onSite"
                    id="onSite"
                    checked={values.onSite ?? false}
                    onChange={handleChange}
                    errors={errors}
                    label={t('EquipmentForm.onSiteLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative  mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('EquipmentForm.availableDateLabel')}
                    labelClassName="w-[50%]"
                    id="availableDate"
                    name="availableDate"
                    value={new Date(values.availableDate)}
                    defaultDate={new Date(values.availableDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'availableDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.availableDate}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('EquipmentForm.purchaseDateLabel')}
                    id="purchaseDate"
                    name="purchaseDate"
                    labelClassName="w-[50%]"
                    value={new Date(values.purchaseDate)}
                    defaultDate={new Date(values.purchaseDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'purchaseDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.purchaseDate ?? ''}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative  mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="cost"
                    id="cost"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.cost}
                    label={t('EquipmentForm.costLabel')}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  <Dropdown
                    labelDir="Above"
                    name="currency"
                    id="currency"
                    label={t('CompanySubcontractorForm.currencyLabel')}
                    onChange={handleChange}
                    value={values.currency}
                    errors={errors.currency}
                    optionValue="id"
                    labelClassName="pl-0"
                    options={currencyOptions ?? []}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('EquipmentForm.lastMaintenanceDateLabel')}
                    id="lastMaintenanceDate"
                    name="lastMaintenanceDate"
                    labelClassName="w-[50%]"
                    value={new Date(values.lastMaintenanceDate)}
                    defaultDate={new Date(values.lastMaintenanceDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'lastMaintenanceDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.lastMaintenanceDate ?? ''}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative  mb-6 w-full">
                  <DatePickerDefault
                    labelDir="Above"
                    label={t('EquipmentForm.nextMaintenanceDateLabel')}
                    id="nextMaintenanceDate"
                    name="nextMaintenanceDate"
                    labelClassName="w-[50%]"
                    value={new Date(values.nextMaintenanceDate)}
                    defaultDate={new Date(values.nextMaintenanceDate)}
                    onChange={(date) => {
                      handleChange({
                        target: {
                          name: 'nextMaintenanceDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors.nextMaintenanceDate}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="responsiblePerson"
                    id="responsiblePerson"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.responsiblePerson}
                    label={t('EquipmentForm.responsiblePersonLabel')}
                  />
                </div>

                {/* <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="status"
                    id="status"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.status}
                    label={t('EquipmentForm.statusLabel')}
                  />
                </div> */}
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="locationDetails"
                    id="locationDetails"
                    placeholder=" "
                    labelClassName="w-[50%]"
                    onChange={handleChange}
                    errors={errors}
                    value={values.locationDetails}
                    label={t('EquipmentForm.locationDetailsLabel')}
                  />
                </div>

                {/* */}

                <Dropdown
                  name="status"
                  id="status"
                  translatedNameSpace="EquipmentStatus"
                  isTranslated={true}
                  optionTransalatedKey="name"
                  label={t('EquipmentForm.statusLabel')}
                  value={values.status}
                  onChange={handleChange}
                  optionValue="id"
                  labelClassName="pl-0"
                  error={errors.status}
                  options={EquipmentStatusOptions ?? []}
                />
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default EquipmentForm;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classNames } from './shared/Utils';

export interface ICurrencyInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label: string;
  errors?: any;
  name: string;
  labelDir?: 'inLine' | 'Above';
  containerClass?: string;
  labelClassName?: string;
  currency?: string;
}

function CurrencyInput({
  id,
  name,
  placeholder,
  label,
  errors,
  labelDir = 'Above',
  containerClass,
  labelClassName,
  value,
  onChange,
  currency = 'EUR',
  ...rest
}: ICurrencyInputProps) {
  const { t } = useTranslation();
  const [displayValue, setDisplayValue] = useState<string>('');
  const [isFocused, setIsFocused] = useState(false);

  // Format number to French currency string
  const formatCurrency = (num: number | string): string => {
    if (!num && num !== 0) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(num));
  };

  // Parse string to number
  const parseValue = (str: string): string => {
    if (!str) return '';
    // Remove all non-digit characters except decimal point and minus
    return (
      str
        .replace(/[^\d,.-]/g, '')
        // Replace comma with dot for decimal
        .replace(',', '.')
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = parseValue(e.target.value);
    setDisplayValue(rawValue);

    if (onChange) {
      onChange({
        ...e,
        target: {
          ...e.target,
          name,
          value: rawValue ? parseFloat(rawValue) : '',
        },
      });
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    const rawValue = parseValue(displayValue || (value?.toString() ?? ''));
    setDisplayValue(rawValue);
    rest.onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (displayValue) {
      const numValue = parseFloat(displayValue);
      const formatted = formatCurrency(numValue);
      setDisplayValue(formatted);
    }
    rest.onBlur?.(e);
  };

  return (
    <div
      className={classNames(
        containerClass ?? '',
        labelDir == 'Above' ? 'relative' : 'inline-flex items-baseline gap-4'
      )}
    >
      <label
        htmlFor={id}
        className={classNames(
          labelClassName ?? '',
          `mb-2 block w-[30%] text-sm font-medium text-gray-900 dark:text-white
          ${errors && errors[name] ? 'text-red-700 dark:text-red-500' : ''}`
        )}
      >
        {label}
      </label>
      <div className="relative w-full">
        <input
          type="text"
          id={id}
          className={`
            block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 
            text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 
            dark:border-gray-600 dark:bg-gray-700 dark:text-white 
            dark:placeholder-gray-400 dark:focus:border-blue-500 
            dark:focus:ring-blue-500 ${rest.className}
            ${
              errors && errors[name]
                ? 'border-red-500 text-red-900 placeholder-red-700 focus:border-red-500 focus:ring-red-500 dark:border-red-400'
                : ''
            }
          `}
          placeholder={placeholder}
          value={
            isFocused
              ? displayValue
              : displayValue || formatCurrency(value as string | number) || ''
          }
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        <span className="absolute right-3 top-2.5 text-sm text-gray-500">
          {currency}
        </span>
      </div>
      {errors && errors[name] && (
        <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          {t(errors[name])}
        </p>
      )}
    </div>
  );
}

export default CurrencyInput;

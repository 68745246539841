import React, { useEffect, useState } from 'react';
import DefaultLayout from '@/layout/DefaultLayout';
import Breadcrumb from '@/components/Breadcrumb';
import { default as DefaultInput } from '@/components/Input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import {
  Country,
  getCountries,
  CountriesResponse,
} from '@/services/CountryService';
import {
  Project,
  getProjectById,
  saveProject,
} from '@/services/ProjectService';
import Spinner from '@/components/Spinner';
import { useNavigate, useParams } from 'react-router-dom';
import Dropdown from '@/components/DropDown';
import { getCompanyId } from '@/services/AuthService';
import { toast } from 'react-hot-toast';
import {
  ProjectContractType,
  ProjectContractTypeOptions,
} from '@/enums/ProjectContractType';
import { JVType, JVTypeOptions } from '@/enums/JVType';
import { Currency, currencyOptions } from '@/enums/Currency';
import { ProjectType } from '@/enums/ProjectType';
import { EmployeesResponse, getEmployees } from '@/services/EmployeeService';
import MultiSelect, { Options } from '@/components/MultiSelect';
import {
  CompetitorsResponse,
  getCompetitors,
} from '@/services/CompetitorService';
import { PartnersResponse, getPartners } from '@/services/PartnerService';
import {
  ProjectExecutorsResponse,
  getProjectExecutors,
} from '@/services/ProjectExecutorService';
import {
  ProjectOwnersResponse,
  getProjectOwners,
} from '@/services/ProjectOwnerService';
import {
  CompanySubcontractorsResponse,
  getCompanySubcontractors,
} from '@/services/CompanySubcontractorService';
import ReactDatePickerDefault from '@/components/ReactDatePickerDefault';
import Checkbox from '@/components/Checkbox';
import FormButtons from '@/components/shared/FormButtons';
import CurrencyInput from '@/components/CurrencyInput';
import TextArea from '@/components/TextArea';
const validationSchema = Yup.object().shape({
  title: Yup.string().required('ProjectForm.titleRequired'),
  description: Yup.string().required('ProjectForm.descriptionRequired'),
  offerNumber: Yup.number().required('ProjectForm.offerNumberRequired'),
  submissionDate: Yup.date().required('ProjectForm.submissionDateRequired'),
  name: Yup.string().required('ProjectForm.nameRequired'),
  siteAddress: Yup.string().required('ProjectForm.siteAddressRequired'),
  countryId: Yup.number().required('ProjectForm.countryIdRequired'),
  startDate: Yup.date().required('ProjectForm.startDateRequired'),
  endDate: Yup.date().required('ProjectForm.endDateRequired'),
  durationMonths: Yup.number().required('ProjectForm.durationMonthsRequired'),
  client: Yup.string().required('ProjectForm.clientRequired'),
  engineer: Yup.string().required('ProjectForm.engineerRequired'),
  projectAmount: Yup.number().required('ProjectForm.projectAmountRequired'),
  projectCurrency: Yup.string().required('ProjectForm.projectCurrencyRequired'),
});

interface FormValues extends Project {}

function ProjectsPOAForm() {
  const { id } = useParams();
  const editForm = id !== 'new';
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [selectedPartnersIds, setSelectedPartnersIds] = useState<string[]>([]);
  const [selectedCompetitorsIds, setSelectedCompetitorsIds] = useState<
    string[]
  >([]);
  const [selectedSubContractorsIds, setSelectedSubContractorsIds] = useState<
    string[]
  >([]);
  const [formikValuesState, setFormikValuesState] = useState<FormValues>({
    companyId: getCompanyId()?.toString() ?? '',
    title: '',
    description: '',
    offerNumber: 0,
    submissionDate: new Date(),
    name: '',
    siteAddress: '',
    competitorsIds: [],
    startDate: new Date(),
    endDate: new Date(),
    durationMonths: 0,
    type: ProjectType.Barrage,
    partnersIds: [],
    client: '',
    engineer: '',
    subContractorsIds: [],
    projectAmount: 0,
    projectCurrency: Currency.USD,
    isConsortium: false,
    consortiumPercentage: 0,
    jointVentureType: JVType.Alliance,
    position: '',
    contractType: ProjectContractType.Base,
    isVariantAccepted: false,
    isDesignStudyIncluded: false,
    hasExperienceInCountry: false,
    experienceStatus: null,
    advancePaymentStatus: false,
    advancePaymentAmount: 0,
    retentionStatus: false,
    retentionAmount: 0,
    priceRating: 0,
    technicalRating: 0,
    deadlineRating: 0,
    bidManagerId: '',
    projectOwnerId: '',
    projectExecutorId: '',
  });
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = React.useState<Country[]>([]);

  const {
    data: projectData,
    isLoading,
    refetch: refetchProject,
  } = useQuery<Project | undefined>(
    ['project', id],
    async () => await getProjectById(id!),
    {
      enabled: editForm,
      refetchOnWindowFocus: false,
      staleTime: 6000,
      onSuccess: (project) => {
        if (project) {
          setFormikValuesState((prev) => ({ ...prev, ...project }));
        }
      },
    }
  );

  const { data, isLoading: countryLoading } = useQuery<
    CountriesResponse | undefined
  >(['countries'], () => getCountries({ from: 0, take: 0 }), {
    onSuccess: (data) => {
      setCountries(data?.countries!);
    },
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });
  const { data: employees, isLoading: employeeLoading } = useQuery<
    EmployeesResponse | undefined
  >(['employees'], () => getEmployees({ from: 0, take: 0 }), {
    refetchOnWindowFocus: false,
    staleTime: 6000,
    keepPreviousData: true,
  });
  const handleSaveProject = async (
    values: FormValues,
    { resetForm, validateForm }: any
  ) => {
    setLoading(true);

    try {
      const companyId = getCompanyId();
      const project: Project = {
        ...values,
        partnersIds: selectedPartnersIds,
        competitorsIds: selectedCompetitorsIds,
        subContractorsIds: selectedSubContractorsIds,
      };

      const data = await saveProject(project);
      if (data) {
        if (editForm) {
          toast.success(
            t('SuccessUpdateMessage', {
              name: t('ToastMsg.Project'),
            })
          );
        } else {
          toast.success(
            t('SuccessMessage', {
              name: t('ToastMsg.Project'),
            })
          );
        }
      }
      if (data && editForm) {
        refetchProject();
      }
      navigate(-1);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoading(false);
      resetForm();
    }
  };

  //? queris of multiselect

  const {
    isLoading: isPartnersLoading,
    // isError,
    data: partners,
  } = useQuery<PartnersResponse | undefined>(
    ['partners'],
    () =>
      getPartners({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    isLoading: isCompetitorsLoading,
    //  isError,
    data: competitors,
  } = useQuery<CompetitorsResponse | undefined>(
    ['competitors'],
    () =>
      getCompetitors({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    isLoading: isSubContractorsLoading,
    //  isError,
    data: subContractors,
  } = useQuery<CompanySubcontractorsResponse | undefined>(
    ['subContractors'],
    () =>
      getCompanySubcontractors({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    isLoading: isProjectOwnersLoading,
    // isError,
    data: projectOwners,
  } = useQuery<ProjectOwnersResponse | undefined>(
    ['projectOwners'],
    () =>
      getProjectOwners({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );

  const {
    isLoading: isProjectExecutorsLoading,
    //isError,
    data: projectExecutors,
  } = useQuery<ProjectExecutorsResponse | undefined>(
    ['projectExecutors'],
    () =>
      getProjectExecutors({
        fromvalue: 0,
        takevalue: 0,
        search: '',
      }),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
      keepPreviousData: true,
    }
  );
  //?......................

  useEffect(() => {
    if (editForm && projectData) {
      setFormikValuesState((prev) => ({ ...prev, ...projectData }));

      if (subContractors && competitors && partners) {
        setSelectedCompetitorsIds(projectData?.competitorsIds ?? []);
        setSelectedSubContractorsIds(projectData?.subContractorsIds ?? []);
        setSelectedPartnersIds(projectData?.partnersIds ?? []);
      }
    }
  }, [editForm, projectData, subContractors, competitors, partners]);

  return (
    <DefaultLayout>
      <Breadcrumb
        pageName={
          editForm ? t('ProjectForm.editProject') : t('ProjectForm.addProject')
        }
      />
      {loading && countryLoading ? (
        <Spinner />
      ) : (
        <Formik
          initialValues={formikValuesState}
          validationSchema={validationSchema}
          onSubmit={handleSaveProject}
          enableReinitialize={editForm}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="title"
                    id="title"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.title}
                    label={t('ProjectForm.titleLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <TextArea
                    //labelClassName="w-[50%]"
                    type="text"
                    name="description"
                    id="description"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.description}
                    label={t('ProjectForm.descriptionLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="number"
                    name="offerNumber"
                    id="offerNumber"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.offerNumber}
                    label={t('ProjectForm.offerNumberLabel')}
                  />
                </div>
                <div className="group relative  mb-6 w-full">
                  {/* <DefaultInput
                    labelClassName="w-[50%]"
                    type="date"
                    name="submissionDate"
                    id="submissionDate"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.submissionDate}
                    label={t('ProjectForm.submissionDateLabel')}
                  /> */}

                  <ReactDatePickerDefault
                    name="submissionDate"
                    id="submissionDate"
                    label={t('ProjectForm.submissionDateLabel')}
                    value={values.submissionDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'submissionDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['submissionDate']}
                  />
                </div>
              </div>
              {/* Project owners and Other Relations  */}

              <div className="grid md:grid-cols-2 md:gap-6">
                <MultiSelect
                  id="partners"
                  name="partners"
                  label={t('Partners.pageName')}
                  optionLabel="name"
                  optionValue="id"
                  options={partners?.partners ?? []}
                  selectedValue={selectedPartnersIds}
                  onChange={(selectedOptions) => {
                    setSelectedPartnersIds(selectedOptions);
                    setFieldValue('partners', selectedOptions);
                  }}
                />

                <div className="group relative  mb-6 w-full">
                  <MultiSelect
                    id="competitors"
                    name="competitors"
                    label={t('Competitors.pageName')}
                    optionLabel="name"
                    optionValue="id"
                    options={competitors?.competitors ?? []}
                    selectedValue={selectedCompetitorsIds}
                    onChange={(selectedOptions) => {
                      console.log(
                        '🚀 ~ ProjectsPOAForm ~ selectedOptions:',
                        selectedOptions
                      );
                      setSelectedCompetitorsIds(selectedOptions);
                      setFieldValue('competitors', selectedOptions);
                    }}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <MultiSelect
                  id="subContractors"
                  name="subContractors"
                  label={t('SubContractors.pageName')}
                  optionLabel="name"
                  optionValue="id"
                  options={subContractors?.companySubcontractors ?? []}
                  selectedValue={selectedSubContractorsIds}
                  onChange={(selectedOptions) => {
                    setSelectedSubContractorsIds(selectedOptions);
                    setFieldValue('subContractors', selectedOptions);
                  }}
                />
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="projectExecutorId"
                    id="projectExecutorId"
                    onChange={handleChange}
                    labelClassName="w-[50%]"
                    optionValue="id"
                    optionLabel="name"
                    errors={errors.projectExecutorId}
                    value={values.projectExecutorId}
                    label={t('ProjectForm.projectExecutorIdLabel')}
                    options={projectExecutors?.projectExecutorMOEs ?? []} // Assume countryOptions is defined
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="projectOwnerId"
                    id="projectOwnerId"
                    labelClassName="w-[50%]"
                    onChange={handleChange}
                    optionValue="id"
                    optionLabel="name"
                    errors={errors.projectOwnerId}
                    value={values.projectOwnerId}
                    label={t('ProjectForm.projectOwnerIdLabel')}
                    options={projectOwners?.projectOwnerMOAs ?? []} // Assume countryOptions is defined
                  />
                </div>
              </div>

              {/*end of relations   */}

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="name"
                    id="name"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.name}
                    label={t('ProjectForm.nameLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="siteAddress"
                    id="siteAddress"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.siteAddress}
                    label={t('ProjectForm.siteAddressLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="countryId"
                    id="countryId"
                    optionValue="id"
                    optionLabel="name"
                    onChange={handleChange}
                    errors={errors.countryId}
                    value={values.countryId}
                    label={t('ProjectForm.countryIdLabel')}
                    options={countries ?? []}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  {/* <DefaultInput
                    labelClassName="w-[50%]"
                    type="date"
                    name="startDate"
                    id="startDate"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.startDate}
                    label={t('ProjectForm.startDateLabel')}
                  /> */}

                  <ReactDatePickerDefault
                    name="startDate"
                    id="startDate"
                    label={t('ProjectForm.startDateLabel')}
                    value={values.startDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'startDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['startDate']}
                  />
                </div>
              </div>
              <div className=" grid md:grid-cols-2 md:gap-6">
                <div className="group relative  mb-6 w-full">
                  {/* <DefaultInput
                    labelClassName="w-[50%]"
                    type="date"
                    name="endDate"
                    id="endDate"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.endDate}
                    label={t('ProjectForm.endDateLabel')}
                  /> */}

                  <ReactDatePickerDefault
                    name="endDate"
                    id="endDate"
                    label={t('ProjectForm.endDateLabel')}
                    value={values.endDate ?? new Date()}
                    onChange={(date: Date) => {
                      handleChange({
                        target: {
                          name: 'endDate',
                          value: date,
                        },
                      });
                    }}
                    errors={errors['endDate']}
                  />
                </div>
                <div className="group relative mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="number"
                    name="durationMonths"
                    id="durationMonths"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.durationMonths}
                    label={t('ProjectForm.durationMonthsLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="client"
                    id="client"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.client}
                    label={t('ProjectForm.clientLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    labelClassName="w-[50%]"
                    type="text"
                    name="engineer"
                    id="engineer"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.engineer}
                    label={t('ProjectForm.engineerLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <CurrencyInput
                    labelClassName="w-[50%]"
                    //type="number"
                    currency={Currency[values.projectCurrency]}
                    name="projectAmount"
                    id="projectAmount"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.projectAmount}
                    label={t('ProjectForm.projectAmountLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="projectCurrency"
                    id="projectCurrency"
                    onChange={handleChange}
                    errors={errors}
                    value={values.projectCurrency}
                    label={t('ProjectForm.projectCurrencyLabel')}
                    options={currencyOptions} // Assume currencyOptions is defined
                  />
                </div>
              </div>

              <div className="group relative z-0 mb-6 w-full">
                <DefaultInput
                  type="number"
                  name="consortiumPercentage"
                  id="consortiumPercentage"
                  placeholder=" "
                  onChange={handleChange}
                  errors={errors}
                  value={values.consortiumPercentage}
                  label={t('ProjectForm.consortiumPercentageLabel') + ' %'}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <Dropdown
                  name="jointVentureType"
                  id="jointVentureType"
                  onChange={handleChange}
                  errors={errors}
                  value={values.jointVentureType}
                  label={t('ProjectForm.jointVentureTypeLabel')}
                  options={JVTypeOptions}
                />
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="text"
                    name="position"
                    id="position"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.position}
                    label={t('ProjectForm.positionLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="contractType"
                    id="contractType"
                    onChange={handleChange}
                    errors={errors}
                    value={values.contractType}
                    label={t('ProjectForm.contractTypeLabel')}
                    options={ProjectContractTypeOptions}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="retentionAmount"
                    id="retentionAmount"
                    labelClassName="w-[50%]"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.retentionAmount}
                    label={t('ProjectForm.retentionAmountLabel')}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <CurrencyInput
                    ///type="number"
                    name="advancePaymentAmount"
                    id="advancePaymentAmount"
                    labelClassName="w-[50%]"
                    placeholder=" "
                    currency={Currency[values.projectCurrency]}
                    onChange={handleChange}
                    errors={errors}
                    value={values.advancePaymentAmount}
                    label={t('ProjectForm.advancePaymentAmountLabel')}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="priceRating"
                    labelClassName="w-[50%]"
                    id="priceRating"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.priceRating}
                    label={t('ProjectForm.priceRatingLabel') + ' %'}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="technicalRating"
                    id="technicalRating"
                    placeholder=" "
                    labelClassName="w-[50%]"
                    onChange={handleChange}
                    errors={errors}
                    value={values.technicalRating}
                    label={t('ProjectForm.technicalRatingLabel') + ' %'}
                  />
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="group relative z-0 mb-6 w-full">
                  <DefaultInput
                    type="number"
                    name="deadlineRating"
                    id="deadlineRating"
                    labelClassName="w-[50%]"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.deadlineRating}
                    label={t('ProjectForm.deadlineRatingLabel') + ' %'}
                  />
                </div>
                <div className="group relative z-0 mb-6 w-full">
                  <Dropdown
                    name="bidManagerId"
                    id="bidManagerId"
                    onChange={handleChange}
                    errors={errors}
                    optionValue="id"
                    optionLabel="employeeFullName"
                    value={values.bidManagerId}
                    labelClassName="w-[50%]"
                    label={t('ProjectForm.bidManagerIdLabel')}
                    options={employees?.employees ?? []} // Assume contractTypeOptions is defined
                  />
                  {/* <DefaultInput
                    type="text"
                    name="bidManagerId"
                    labelClassName="w-[50%]"
                    id="bidManagerId"
                    placeholder=" "
                    onChange={handleChange}
                    errors={errors}
                    value={values.bidManagerId}
                    label={t('ProjectForm.bidManagerIdLabel')}
                  /> */}
                </div>
              </div>

              <div className="grid md:grid-cols-3 md:gap-6">
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.hasExperienceInCountryLabel')} </label>
                  <Checkbox
                    // type="checkbox"
                    name="hasExperienceInCountry"
                    id="hasExperienceInCountry"
                    //className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={handleChange}
                    checked={values.hasExperienceInCountry}
                    label={''}
                  />
                </div>
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.isVariantAcceptedLabel')} </label>
                  <Checkbox
                    name="isVariantAccepted"
                    id="isVariantAccepted"
                    //className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={handleChange}
                    checked={values.isVariantAccepted}
                    label={''}
                  />
                </div>
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.isDesignStudyIncludedLabel')} </label>
                  <Checkbox
                    //type="checkbox"
                    name="isDesignStudyIncluded"
                    //className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    id="isDesignStudyIncluded"
                    onChange={handleChange}
                    checked={values.isDesignStudyIncluded}
                    label={''}
                  />
                </div>
              </div>

              <div className="grid md:grid-cols-3 md:gap-6">
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.experienceStatusLabel')}</label>
                  <Checkbox
                    //type="checkbox"
                    name="experienceStatus"
                    id="experienceStatus"
                    // className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={handleChange}
                    checked={values.experienceStatus || false}
                  />
                </div>
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.advancePaymentStatusLabel')} </label>
                  <Checkbox
                    // type="checkbox"
                    name="advancePaymentStatus"
                    id="advancePaymentStatus"
                    // className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={handleChange}
                    checked={values.advancePaymentStatus}
                  />
                </div>
                <div className="group relative z-0 mx-4 mb-6 flex w-full items-center gap-2">
                  <label>{t('ProjectForm.retentionStatusLabel')} </label>
                  <Checkbox
                    //type="checkbox"
                    name="retentionStatus"
                    id="retentionStatus"
                    // className=" h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                    onChange={handleChange}
                    checked={values.retentionStatus}
                  />
                </div>
              </div>
              <FormButtons resetForm={resetForm} loading={loading} />
            </form>
          )}
        </Formik>
      )}
    </DefaultLayout>
  );
}

export default ProjectsPOAForm;

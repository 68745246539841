// import moment from 'moment';
import { TFunction } from 'i18next';
import moment from 'moment-timezone';
const userTimeZone = moment.tz.guess();

const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export function AsUTC(date: Date | null | undefined): Date | null {
  if (date == null) {
    return null;
  }
  return moment(date).utcOffset(0, true).startOf('day').toDate();
}
export function AsUserTimeZone(date: Date | null | undefined): Date | null {
  return moment.utc(date).tz(userTimeZone).toDate();
}

export const checkData = (value: Date | undefined, t: TFunction) => {
  const dateValue = moment.utc(value);

  if (dateValue.format('YYYY-MM-DD') === '0001-01-01') {
    return t('NoDateAvailable');
  }

  const formattedValue = dateValue.format('DD/MM/YYYY');
  return formattedValue;
};

// export const formatCurrency = (value: number | string) => (
//   <NumericFormat
//     value={value}
//     displayType="text"
//     thousandSeparator=","
//     decimalSeparator="."
//     decimalScale={2}
//     fixedDecimalScale
//   />
// );

export const formatCurrencyString = (value: number | string): string => {
  // Use Intl.NumberFormat for more flexibility
  return new Intl.NumberFormat('fr-FR', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(value));
};

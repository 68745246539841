import React, { useState } from 'react';
import ProjectDoc from '/src/images/icon/new-document.svg';
import MenuIcon from '/src/images/icon/menu.svg';
import DescrptionIcon from '/src/images/icon/description.svg';
import ProjectTeamIcon from '/src/images/icon/projectTeam.svg';
import ProjectMeetingIcon from '/src/images/icon/board-meeting.svg';
import InterfaceRequestsIcon from '/src/images/icon/request.svg';
import DeliverablesIcon from '/src/images/icon/task.svg';
import OuvrageEntriesIcon from '/src/images/icon/folder.svg';
import ProjectTaskIcon from '/src/images/icon/projectTask.svg';
import ProjectPalningIcon from '/src/images/icon/time-management.svg';
import ProjectDetailsIcon from '/src/images/icon/document.svg';
import ProjectAOCASEIcon from '/src/images/icon/status.svg';
import PlanningIcon from '/src/images/icon/planning.svg';
import QuestionsIcon from '/src/images/icon/questions.svg';
import JournalsIcon from '/src/images/icon/journal.svg';
import WorkHoursIcon from '/src/images/icon/working-hours.svg';
import RisksIcon from 'src/images/icon/risks.svg';

import {
  ProjectAOStatus,
  ProjectAOStatusColor,
  ProjectAOStatusTextColor,
} from '@/enums/ProjectAOStatus';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface ProjectMenuType {
  title: string;
  path: string;
  Icon?: any;
}

const ProjectMenus: ProjectMenuType[] = [
  {
    title: 'Details',
    path: '/details',
    Icon: ProjectDetailsIcon,
  },

  {
    title: 'Charastricstics',
    path: '/Charastristics',
    Icon: DescrptionIcon,
  },

  {
    title: 'TeamMembers',
    path: '/TeamMembers',
    Icon: ProjectTeamIcon,
  },
  {
    title: 'ProjectMeetings',
    path: '/ProjectMeetings',
    Icon: ProjectMeetingIcon,
  },
  {
    title: 'InterfaceRequests',
    path: '/InterfaceRequests',
    Icon: InterfaceRequestsIcon,
  },
  {
    title: 'Deliverables',
    path: '/Deliverables',
    Icon: DeliverablesIcon,
  },
  {
    title: 'OuvrageEntries',
    path: '/OuvrageEntries',
    Icon: OuvrageEntriesIcon,
  },
  {
    title: 'ProjectTasks',
    path: '/ProjectTasks',
    Icon: ProjectTaskIcon,
  },
  {
    title: 'PlanningAOTasks',
    path: '/PlanningAOTasks',
    Icon: PlanningIcon,
  },
  {
    title: 'QuestionResponses',
    path: '/QuestionResponses',
    Icon: QuestionsIcon,
  },
  {
    title: 'Journals',
    path: '/JournalEntries',
    Icon: JournalsIcon,
  },
  {
    title: 'WorkCharges',
    path: '/WorkCharges',
    Icon: WorkHoursIcon,
  },
  {
    title: 'Risks',
    path: '/Risks',
    Icon: RisksIcon,
  },

  {
    title: 'SousProjects',
    path: '/SousProjects',
    Icon: ProjectPalningIcon,
  },
  {
    title: 'State',
    path: '/project-state',
    Icon: ProjectAOCASEIcon,
  },
];
export interface ProjectCardProps {
  state: ProjectAOStatus;
  bidManagerName: string;
  title: string;
  date: string;
  id: string;
}

function ProjectCard({
  id,
  state,
  title,
  date,
  bidManagerName,
}: ProjectCardProps) {
  const stateName = ProjectAOStatus[state];

  var bgcolor = ProjectAOStatusColor[stateName];
  var textcolor = ProjectAOStatusTextColor[stateName];
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="relative flex h-90 w-[90%]">
      <MenuIcon
        onClick={() => setIsOpen((pr) => !pr)}
        className="absolute -right-6 top-2 mx-auto flex h-7 w-7"
        style={{ color: bgcolor }}
      />
      <div
        className="flex w-full flex-col justify-between rounded-md border-[1px]"
        style={{ borderColor: bgcolor }}
      >
        <div>
          <div className="flex items-center justify-between">
            <p
              className="ml-2 mt-2 rounded-md p-2 text-xs"
              style={{
                backgroundColor: bgcolor,
                color: textcolor,
              }}
            >
              {ProjectAOStatus[state]}
            </p>
            <span className="mr-2 h-5 w-5 rounded-full border bg-bodydark1" />
          </div>
          <ProjectDoc className="mx-auto flex h-40 w-20" />
        </div>
        <div className="border-t-[1px]" style={{ borderColor: bgcolor }}>
          <p className="flex-wrap text-ellipsis px-4 py-2 text-sm text-boxdark dark:text-white">
            {title}
          </p>

          <div className="flex justify-between px-2 py-2 text-sm">
            <p>{date}</p>

            <p>{bidManagerName}</p>
          </div>
        </div>
      </div>

      {isOpen && <MenuPopup projectId={id} />}
    </div>
  );
}

export const MenuPopup = ({ projectId }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="absolute right-0 top-4  my-2 w-60 rounded-md border-[1px] bg-white py-2 dark:bg-boxdark-2 dark:text-bodydark">
      {ProjectMenus.map(({ Icon, title, path }) => (
        <div
          onClick={(e) => navigate(`${projectId}${path}`)}
          className="flex cursor-pointer items-center"
        >
          <Icon className="h-8 w-8 p-2" />
          <p>{t(`ProjectMenus.${title}`)}</p>
        </div>
      ))}
    </div>
  );
};
export default ProjectCard;

import { useMemo, useState } from 'react';
import DefaultInput from '@/components/Input';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import Spinner from '@/components/Spinner';
import { useParams } from 'react-router-dom';
import {
  changeTrainingRequestState,
  ChangeTrainingRequestStateProps,
  getTrainingRequest,
  TrainingRequest,
} from '@/services/TrainingRequestService';
import TextArea from '@/components/TextArea';
import Checkbox from '@/components/Checkbox';
import { useAuth } from '@/context/AuthContext';
import { TrainingRequestState } from '@/enums/TrainingRequestState';
import { UserRoles } from '@/enums/UsersRole';
import Breadcrumb from '@/components/Breadcrumb';
import DefaultLayout from '@/layout/DefaultLayout';
import { stateColors } from '@/enums/RequestLeaveState';
import { UserResponsibility } from '@/enums/UserResponsibility';
import {
  ChangeDocumentRequestStateProps,
  DocumentRequest,
  changeDocRequestState,
  getDocumentRequest,
} from '@/services/DocumentRequestsService';
import { DocumentRequestStatus } from '@/enums/DocumentRequestStatus';
import Dropdown from '@/components/DropDown';
import { DocTypeOptions } from '@/enums/DocumentTypeEnum';
import moment from 'moment';
import { checkData } from '@/components/shared/Utils';

const DocumentRequestDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const readonly = true;
  const { user } = useAuth();

  const isRHAdmin =
    user?.responsibilityClaim ===
      UserResponsibility[UserResponsibility.GestionDesRH].toString() ||
    user?.role === UserRoles.Admin;

  const {
    data: docRequestDetails,
    isLoading,
    refetch,
  } = useQuery<DocumentRequest>(
    ['docReq', id],
    async () => await getDocumentRequest(id!),
    {
      refetchOnWindowFocus: false,
      staleTime: 6000,
    }
  );

  if (isLoading || docRequestDetails === undefined) {
    return <Spinner />;
  }

  const handleRejectDocRequest = async (values: DocumentRequest) => {
    const tRState: ChangeDocumentRequestStateProps = {
      id: values.id!,
      state: DocumentRequestStatus.Rejected,
      comment: '',
    };

    const data = await changeDocRequestState(tRState);
    if (data) {
      refetch();
    }
  };
  const handleIsProcessedDocRequest = async (values: DocumentRequest) => {
    const tRState: ChangeDocumentRequestStateProps = {
      id: values.id!,
      state: DocumentRequestStatus.Processed,
      comment: '',
    };

    const data = await changeDocRequestState(tRState);
    if (data) {
      refetch();
    }
  };
  const handleInProgressDocRequest = async (values: DocumentRequest) => {
    const tRState: ChangeDocumentRequestStateProps = {
      id: values.id!,
      state: DocumentRequestStatus.InProgress,
      comment: '',
    };

    const data = await changeDocRequestState(tRState);
    if (data) {
      refetch();
    }
  };

  const formButtons = () => {
    if (
      docRequestDetails?.status !== DocumentRequestStatus.Pending &&
      docRequestDetails?.status !== DocumentRequestStatus.InProgress
    ) {
      return;
    }

    if (isRHAdmin) {
      switch (docRequestDetails.status) {
        case DocumentRequestStatus.Pending:
          return (
            <div className=" flex items-end justify-end">
              <button
                type="button"
                id="cancelForm"
                className="mr-2 inline-flex items-center rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
                onClick={() => handleRejectDocRequest(docRequestDetails)}
              >
                {t('DocumentRequestStatus.Rejected')}
              </button>
              <button
                type="submit"
                className="mr-2 inline-flex items-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                disabled={isLoading}
                onClick={() => handleIsProcessedDocRequest(docRequestDetails)}
              >
                {t('DocumentRequestStatus.Processed')}
              </button>
              <button
                type="submit"
                className="mr-2 inline-flex items-center rounded-lg bg-purple-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-purple-800 focus:outline-none focus:ring-4 focus:ring-purple-300 dark:focus:ring-purple-800"
                disabled={isLoading}
                onClick={() => handleInProgressDocRequest(docRequestDetails)}
              >
                {t('DocumentRequestStatus.InProgress')}
              </button>
            </div>
          );
          break;

        case DocumentRequestStatus.InProgress:
          return (
            <div className=" flex items-end justify-end">
              <button
                type="button"
                id="cancelForm"
                className="mr-2 inline-flex items-center rounded-lg bg-red-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 dark:focus:ring-red-800"
                onClick={() => handleRejectDocRequest(docRequestDetails)}
              >
                {t('DocumentRequestStatus.Rejected')}
              </button>
              <button
                type="submit"
                className="mr-2 inline-flex items-center rounded-lg bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-green-800 focus:outline-none focus:ring-4 focus:ring-green-300 dark:focus:ring-green-800"
                disabled={isLoading}
                onClick={() => handleIsProcessedDocRequest(docRequestDetails)}
              >
                {t('DocumentRequestStatus.Processed')}
              </button>
            </div>
          );
          break;

        default:
          break;
      }
    }

    return <></>;
  };

  const getColorizedState = (docRequest: DocumentRequest) => {
    const state = DocumentRequestStatus[docRequest.status!];

    const bgColor = stateColors[state] || 'bg-gray-300';

    return (
      <div
        className={`rounded-md text-center ${bgColor} w-50  px-2.5 py-2.5 text-sm text-white`}
      >
        {state !== null ? ` ${t(`DocumentRequestStatus.${state}`)}` : ''}
      </div>
    );
  };
  return (
    <DefaultLayout>
      <Breadcrumb pageName={t('DocRequest.DocumentRequestDetails')} />

      {isLoading ? (
        <Spinner />
      ) : docRequestDetails === undefined ? (
        <span>No Data Item maybe be deleted</span>
      ) : (
        <>
          <div className="group relative z-0 mb-6  ">
            {getColorizedState(docRequestDetails)}
          </div>
          <div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative z-0 mb-6 w-full">
                <Dropdown
                  id="documentType"
                  name="documentType"
                  optionValue="id"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                  options={DocTypeOptions}
                  label={t('DocRequest.DocType')}
                  value={docRequestDetails.documentType}
                  labelClassName="w-[50%]"
                  isTranslated={true}
                  translatedNameSpace="DocumentTypes"
                  optionTransalatedKey="name"
                  disabled={true}
                />
              </div>
              <div className="group relative z-0 mb-6 w-full">
                <TextArea
                  id="comments"
                  name="comments"
                  label={t('DocRequest.comments')}
                  value={docRequestDetails.comments}
                  readOnly={true}
                />
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="group relative mb-6 w-full">
                <DefaultInput
                  id="decisionDate"
                  name="decisionDate"
                  value={checkData(docRequestDetails.decisionDate, t)}
                  label={t('DocRequest.decisionDate')}
                  disabled={readonly}
                  labelClassName="w-[50%]"
                />
              </div>
              <div className="group relative  mb-6 w-full">
                <DefaultInput
                  id="trainingStartDate"
                  name="trainingStartDate"
                  value={checkData(docRequestDetails.sendingDate, t)}
                  label={t('DocRequest.sendingDate')}
                  disabled={readonly}
                  labelClassName="w-[50%]"
                />
              </div>
            </div>

            {formButtons()}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default DocumentRequestDetails;
